.links-section {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.links-section input {
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
}