.text-section {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.text-area textarea {
  width: 100%;
  min-height: 100px;
  padding: 1rem;
  border-radius: 8px;
}